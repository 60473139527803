<template>
  <Component :is="rootComponentAttrs.component" v-bind="rootComponentAttrs.bind">
    <div :class="['shared-kit-case', cardClass]" :aria-disabled="disabled" @click="caseClickHandler">
      <div class="shared-kit-case__widgets">
        <div class="shared-kit-case__widgets-remove" @click.stop="$emit('remove')">
          <SvgoTrashIcon :width="iconSize" :height="iconSize" />
        </div>
        <ClientOnly>
          <SharedKitProgressCounter v-if="counterVariant" :variant="counterVariant" />
        </ClientOnly>
        <SharedKitEarnings
          v-if="caseData.coinsData"
          :amount="caseData.coinsData"
          :icon="eventData.icon"
          :color="eventData.color"
          :show-rag="variant.showRag"
        />
      </div>
      <div class="shared-kit-case__case">
        <div class="shared-kit-case__case-image">
          <div class="shared-kit-case__case-image--overlay"></div>
          <NuxtImg
            v-if="caseData.image"
            provider="localProvider"
            class="shared-kit-case__case-image--img"
            :src="caseData.image"
            :width="imageSize"
            :height="imageSize"
            loading="lazy"
          />
        </div>
        <ClientOnly>
          <div class="shared-kit-case__case-icon">
            <SvgoCaseCardAdd
              v-if="variant.type === ESharedKitCaseTypes.BATTLE && variant.selected === 0"
              :width="caseCardAddSize"
              :height="caseCardAddSize"
            />
            <div
              v-if="variant.type !== ESharedKitCaseTypes.DEFAULT && variant.selected > 0"
              class="shared-kit-case__case-stepper"
            >
              <div class="shared-kit-case__case-stepper--icon" @click.stop="$emit('minus')">
                <SvgoStepperMinus :width="stepperIconSize" :height="stepperIconSize" />
              </div>
              <div class="shared-kit-case__case-stepper--step">
                {{ variant.selected }}
              </div>
              <div class="shared-kit-case__case-stepper--icon" @click.stop="$emit('plus')">
                <SvgoStepperPlus :width="stepperIconSize" :height="stepperIconSize" />
              </div>
            </div>
            <div
              v-if="variant.type === ESharedKitCaseTypes.DEFAULT && (variant.locked || !!props.caseData.counterEnd)"
              class="shared-kit-case__case-lock"
            >
              <SvgoLockIcon :width="lockSize" :height="lockSize" />
            </div>
          </div>
        </ClientOnly>
      </div>
      <div class="shared-kit-case__content">
        <ClientOnly>
          <SharedKitLabel v-if="caseData.labelData" v-bind="caseData.labelData" />
          <SharedKitPrice :variant="priceVariant" />
        </ClientOnly>
      </div>
      <p v-if="isDefaultLinkCase" class="shared-kit-case__title" @click.stop="caseTitleClickHandler">
        {{ caseData.name }}
      </p>
      <NuxtLinkLocale
        v-else
        class="shared-kit-case__title"
        :to="`${useLinks().OPEN_CASE}${props.caseData.link}`"
        external
        target="_blank"
        @click.stop="() => {}"
      >
        {{ caseData.name }}
      </NuxtLinkLocale>
    </div>
  </Component>
</template>

<script lang="ts" setup>
import {
  ESharedKitProgressCounterTypes,
  type TSharedKitProgressCounterVariant,
} from '../SharedKitProgressCounter/SharedKitProgressCounter.types';
import {
  ECaseImageSize,
  ESharedKitPriceTypes,
  type TSharedKitPriceVariant,
} from '../SharedKitPrice/SharedKitPrice.types';
import type { ISharedKitCaseEmits, ISharedKitCaseProps } from './SharedKitCase.types';
import { ESharedKitCaseTypes } from './SharedKitCase.types';
import type { TCurrencyType, TPossibleNull } from '~/types/Shared.types';
import { CurrencyName } from '~/constants/currency.constants';

const { defaultCurrency } = useProjectSettings();

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const { getBackgroundColor } = GlobalUtils.CSS;

const props = defineProps<ISharedKitCaseProps>();
const emits = defineEmits<ISharedKitCaseEmits>();
const EXTERNAL_LINKS = ['free tastycase', 'Daily case', 'extra case']; // Рудиментарные кейсы ( из секции бесплатной серии )

const cardClass = computed(() => ({
  'shared-kit-case--battle': props.variant.type === 'battle' && props.variant.selected === 0,
  'shared-kit-case--battle-chosen': props.variant.type === 'battle' && props.variant.selected > 0,
  'shared-kit-case--battle-chosen-gold': props.variant.type === 'battle-selected',
  'shared-kit-case--custom': props.variant.type !== 'default',
  'shared-kit-case--small': props.isSmallSize,
}));

const counterVariant = computed<TPossibleNull<TSharedKitProgressCounterVariant>>(() => {
  if (!props.caseData.counterEnd && !props.caseData.counterData) return null;

  if (props.caseData.counterData && props.caseData.counterData.type === 'quantity') {
    return {
      current: props.caseData.counterData.end,
      initial: props.caseData.counterData.start,
      type: ESharedKitProgressCounterTypes.QUANTITY,
    };
  }

  return {
    end: props.caseData.counterData?.end || Number(props.caseData.counterEnd),
    start: props.caseData.counterData?.start || Number(props.caseData.counterStart),
    type: ESharedKitProgressCounterTypes.TIME,
  };
});

const priceVariant = computed<TSharedKitPriceVariant>(() => {
  if (props.caseData.priceData.currency === CurrencyName.COIN) {
    return {
      amount: props.caseData.priceData.price ? props.caseData.priceData.price : 0,
      color: props.eventData.color,
      icon: props.eventData.icon,
      type: ESharedKitPriceTypes.COINS,
    };
  }

  return {
    currency: props.caseData.priceData.currency ?? CurrencyName[defaultCurrency as TCurrencyType],
    oldPrice: props.caseData.priceData.old,
    price: props.caseData.priceData.price!,
    type: ESharedKitPriceTypes.MONEY,
  };
});

const isDefaultLinkCase = computed(() => props.variant.type === ESharedKitCaseTypes.DEFAULT);
const rootComponentAttrs = computed(() => {
  if (!isDefaultLinkCase.value) return { bind: {}, component: 'div' };

  return {
    bind: {
      to: `/open/${props.caseData.link}`,
      external: EXTERNAL_LINKS.includes(props.caseData.link),
    },
    component: resolveComponent('NuxtLinkLocale'),
  };
});

const imageSize = computed(() =>
  props.isSmallSize ? ECaseImageSize.SMALL : changeXL(ECaseImageSize.BIG, ECaseImageSize.SMALL),
);
const caseCardAddSize = computed(() => changeXL(80, 48));
const lockSize = computed(() => changeXL(48, 32));
const iconSize = computed(() => changeXL(28, 24));
const stepperIconSize = computed(() => changeXL(34, 28));

const styleVariables = computed(() => {
  let caseBackground = 'var(--main-color-600)';
  let caseCircle = 'radial-gradient(50% 50% at 50% 50%, var(--main-color-400) 87.5%, var(--main-color-50) 100%)';
  let caseHover = 'var(--main-color-400)';
  let caseShadow = 'var(--main-color-100)';

  if (props.variant.type === ESharedKitCaseTypes.DEFAULT && props.variant.colorData) {
    caseBackground = getBackgroundColor(props.variant.colorData.background || caseBackground);
    caseCircle = getBackgroundColor(props.variant.colorData.circle || caseCircle);
    caseHover = getBackgroundColor(props.variant.colorData.hover || caseHover);
    caseShadow = getBackgroundColor(props.variant.colorData.shadow || caseShadow);
  }

  return {
    caseBackground,
    caseCircle,
    caseHover,
    caseShadow,
  };
});

const caseTitleClickHandler = () => {
  if (isDefaultLinkCase.value) return;
  return navigateTo(
    `${useLinks().OPEN_CASE}${props.caseData.link}`,
    // , {
    // external: true,
    // open: { target: '_blank' },
    // }
  );
};

const caseClickHandler = () => {
  if (isDefaultLinkCase.value) return;
  if (
    props.variant.type === ESharedKitCaseTypes.BATTLE_SELECTED ||
    (props.variant.type === ESharedKitCaseTypes.BATTLE && props.variant.selected)
  ) {
    emits('plus');
    return;
  }
  emits('add');
};
</script>

<style scoped lang="scss">
.shared-kit-case {
  --case-background: v-bind('styleVariables.caseBackground');
  --case-circle: v-bind('styleVariables.caseCircle');
  --case-shadow: v-bind('styleVariables.caseShadow');
  --case-hover: v-bind('styleVariables.caseHover');
}
</style>

<style scoped lang="scss" src="./SharedKitCase.scss" />
